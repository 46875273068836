import { ButtonGroup, Divider, Grid, Link } from "@mui/material";
import React, { useState } from "react";
import BannerComponent from "../../components/banner/BannerComponent";
import StdBannerComponent from "../../components/banner/StdBannerComponent";
import { IoSettingsOutline } from "react-icons/io5";
import performance_img from "../../assets/icons/performance.png";
import collaborate from "../../assets/icons/collaborate.png";
import analysis from "../../assets/icons/analysis.png";

import "./css/homepage.css";
import ButtonReusable from "../../components/buttons/ButtonReusable";
import { HiOutlineCheckCircle } from "react-icons/hi";
import about_us_img from "../../assets/banners/about_us_img.jpg";
import { BsGraphUpArrow } from "react-icons/bs";
import { MdComputer } from "react-icons/md";
import {
  faqs_accordion_details,
  product_card_details,
  project_card_details,
  what_we_do_card_details,
  work_progress_card_details,
} from "../../components/navigation/utils/MainUtils";
import call from "../../assets/icons/call.svg";
import brand from "../../assets/usi_logo.png";
import ums_logo_on_pc from "../../assets/ums_logo_on_pc.png";
import faq_img1 from "../../assets/banners/faq_img1.jpg";
import faq_img2 from "../../assets/banners/faq_img2.jpg";
import product_sample_image from "../../assets/banners/product1.jpg";

import work_process_svg_line from "../../assets/icons/process_line_2.svg";

// accordtion imports
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwiperImages from "../../components/gallery/SwiperImages";

// client gallery reference
import { clients } from "../../components/navigation/utils/MainUtils";
import ProductsSwiperCard from "../../components/gallery/ProductsSwiperCard";
import LightboxComponent from "../../components/lightbox/LightBoxComponent";
import { useNavigate } from "react-router-dom";

const Homepage2 = () => {
  const [expanded, setExpanded] = useState(0);
  const navigate = useNavigate();

  const handleChange = (index) => {
    setExpanded(index);
  };
  const [openLightbox, setOpenLightbox] = useState(false);
  const [video, setVideo] = useState(null);

  const handleOpenLightbox = (video) => {
    setOpenLightbox(true);
    setVideo(video);
  };
  const handeRoute = (route) => {
    navigate(route);
  };
  const handleClose = () => {
    setOpenLightbox(false);
  };

  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
      justifyContent={{ xs: "center", sm: "center", md: "center" }}
      rowSpacing={1}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} id="home-section">
        <StdBannerComponent />
      </Grid>

      {/* our core features section */}
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          columnSpacing={{ xs: 1, sm: 1, md: 5, lg: 5 }}
          justifyContent={{ xs: "center", sm: "center", md: "center" }}
          rowSpacing={1}
          p={2}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="core-feature-section">
              <p>our core features</p>
              <h3> A new way to approach customer-driven data</h3>
            </div>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 1, sm: 1, md: 5, lg: 5 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={5}
              marginBottom="100px"
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="features-container">
                  <div className="icon-container">
                    <img
                      src={performance_img}
                      alt="flat-icon performance"
                      className="img"
                    />
                  </div>
                  <div className="text-details">
                    <h4>Precision in Performance</h4>
                    <p>
                      Our cutting-edge technology ensures seamless execution,
                      from project initiation to delivery, guaranteeing
                      precision at every step.
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="features-container">
                  <div className="icon-container">
                    <img
                      src={collaborate}
                      alt="flat-icon performance"
                      className="img"
                    />
                  </div>
                  <div className="text-details">
                    <h4>Collaboration Redefined</h4>
                    <p>
                      Foster teamwork effortlessly with our integrated
                      collaboration tools, promoting efficient communication and
                      enhancing productivity across your organization.
                    </p>
                  </div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="features-container">
                  <div className="icon-container">
                    <img
                      src={analysis}
                      alt="flat-icon performance"
                      className="img"
                    />
                  </div>
                  <div className="text-details">
                    <h4>Data-Driven Decision-Making</h4>
                    <p>
                      Harness the power of analytics with our robust data
                      insights, empowering your business to make informed
                      decisions and stay ahead in a rapidly evolving market.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="contact-info-container"
            marginBottom="100px"
          >
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 1, sm: 1, md: 5, lg: 5 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={1}
              p={5}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="contact-info">
                  <div className="icon-container">
                    <img src={call} alt="" className="icon" />
                  </div>
                  <div className="text-details">
                    <h4>Call for more info</h4>
                    <p>(+63) 919-0792-240</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="contact-info">
                  <div className="text-details">
                    <h4>Let's Request a Schedule For Free Consultation</h4>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ButtonReusable
                  variant="outlined"
                  buttonName="Contact Us"
                  border="1px solid white"
                  color="white"
                  padding="16px 32px"
                  borderRadius="100px"
                />
              </Grid>
            </Grid>

            <div className="contact-info"></div>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="about-us-main-wrapper"
            marginBottom="100px"
          >
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 1, sm: 1, md: 5, lg: 5 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={1}
              p={5}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="text-details">
                  <h4>Empower Your Vision</h4>
                  <p>Innovate Business Solution for Startup Companies</p>
                  <p>
                    Embark on a transformative journey with us as we redefine
                    the traditional approach to startup solutions. From
                    conceptualization to execution, we offer a comprehensive
                    suite of services that goes beyond conventional boundaries.
                    We don't just craft strategies; we sculpt success stories.
                  </p>

                  <div className="list-container">
                    <div className="list">
                      <HiOutlineCheckCircle className="icon" />
                      <p>
                        Ignite your startup's success with our cutting-edge
                        strategies{" "}
                      </p>
                    </div>
                    <div className="list">
                      <HiOutlineCheckCircle className="icon" />
                      <p>
                        Tailored excellence for your unique startup journey.{" "}
                      </p>
                    </div>{" "}
                    <div className="list">
                      <HiOutlineCheckCircle className="icon" />
                      <p>
                        Conquer competition with our dynamic, creative roadmaps
                        for growth.{" "}
                      </p>
                    </div>{" "}
                  </div>

                  {/* <div className="btn-container">
                    <ButtonReusable
                      buttonName="Explore Now"
                      backgroundColor="#436fea"
                      padding="16px 50px"
                      borderRadius="50px"
                      color="white"
                      transform="translateY(-20px)"
            
                      fontWeight={700}
                    />
                  </div> */}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="img-container">
                  <img src={about_us_img} alt="" className="img" />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={11}
        lg={11}
        className="what-we-do-wrapper"
        sx={{ marginBottom: "50px" }}
      >
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 3 }}
          justifyContent={{ xs: "center", sm: "center", md: "center" }}
          rowSpacing={1}
          id="services-section"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="core-feature-section">
              <p>what we do</p>
              <h3>We Help To Grow Your Business</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 3 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={1}
              p={2}
            >
              {what_we_do_card_details.map((val, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  key={index}
                  className="gen-card-wrapper"
                >
                  <div className="card-wrapper">
                    <div className="content-container">
                      {/* <p className="icon">{val.icon}</p> */}
                      <div className="icon-container">
                        <img src={val.icon} alt="" className="icon" />
                      </div>
                      <p className="heading">{val.heading}</p>
                      <p className="text">{val.text}</p>
                      {/* <p className="route">Learn More</p> */}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={12}
        sm={12}
        md={11}
        lg={11}
        className="team-container"
        marginBottom="100px"
      ></Grid> */}

      {/* <Grid item xs={12} sm={12} md={11} lg={11} className="faqs-section">
        <div className="pulse">
          <div class="layer"></div>
          <div class="layer"></div>
          <div class="layer"></div>
        </div>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          justifyContent={{ xs: "center", sm: "center", md: "center" }}
          rowSpacing={1}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={1}
              p={5}
              className="left-section"
            >
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="faq-asset-container"
              >
                <div className="top-right-asset-wrapper">
                  <img src={faq_img1} alt="" className="img" />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="faq-asset-bottom-container"
              >
                <div className="bottom-left-asset-wrapper">
                  <img src={faq_img2} alt="" className="img" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
            <div className=""></div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="faq-details-section-container">
              <p className="super-title">frequently ask questions?</p>
              <h2 className="header">
                Share the joy of achieving glorious moments & climbed up the top
              </h2>

              {faqs_accordion_details.map((val, index) => (
                <Accordion
                  key={index}
                  elevation={0}
                  defaultExpanded
                  sx={{
                    border: "1px solid #f1e6e6",

                    "& .MuiAccordionSummary-root.Mui-expanded": {
                      backgroundColor: "#3567f3",
                      color: "white",
                      fontWeight: "700",
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    },
                  }}
                  expanded={expanded === index} // Check if the current accordion should be expanded
                  onChange={() => handleChange(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>{val.header}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid> */}

      <Grid
        item
        xs={12}
        sm={12}
        md={11}
        lg={11}
        className="latest-project-section"
      >
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          justifyContent={{ xs: "center", sm: "center", md: "center" }}
          rowSpacing={1}
          p={5}
        >
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={1}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* <p className="super-title">Our Lates Project</p> */}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h2 className="header">Our Products</h2>
              </Grid>

              <LightboxComponent
                open={openLightbox}
                close={handleClose}
                video={video}
              />

              {project_card_details.map((val, index) => (
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className="project-card-container">
                    <div className="content-container">
                      <div className="icon-container">
                        <img src={val.img} alt="" className="img" />
                      </div>
                      <p className="heading">{val.heading}</p>
                      <p className="text">{val.longtext}</p>
                      <p
                        className="route"
                        onClick={() => handeRoute(val.route)}
                        style={{
                          backgroundColor: "white",
                          textAlign: "center",
                          padding: "16px 32px",
                          width: "100%",
                          borderRadius: "8px",
                          textDecoration: "none",
                          color: "#014065",
                          fontWeight: 700,
                        }}
                      >
                        Learn More
                      </p>
                      {/* <Link href={val.video}>Learn more</Link> */}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={11} lg={11} id="about-section"></Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={11}
        lg={11}
        className="work-process-section"
        marginBottom="150px"
        marginTop="100px"
      >
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          justifyContent={{ xs: "center", sm: "center", md: "center" }}
          rowSpacing={1}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={1}
              marginBottom="150px"
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p className="super-title">our work process</p>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h2 className="header">Our Simplified and Effective Process</h2>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 18 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={1}
            >
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="svg-container">
                  <img src={work_process_svg_line} alt="" className="svg" />
                </div>
              </Grid> */}
            </Grid>
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 5 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={1}
              p={2}
            >
              {work_progress_card_details.map((val, index) => (
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div
                    className={`card-container ${
                      index % 2 === 0 ? "even-item" : ""
                    }`}
                  >
                    <div className="content-container">
                      <div className="icon-container">
                        <img src={val.img} alt="" className="img" />
                      </div>
                      <p className="heading">{val.heading}</p>
                      <p className="text">{val.longtext}</p>
                      {/* <p className="route">Learn More</p> */}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="product-section"
        id="products-section"
      >
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 8, lg: 8 }}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          justifyContent={{ xs: "center", sm: "center", md: "center" }}
          rowSpacing={1}
          marginBottom="100px"
          p={3}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="product-section-wrapper"
          >
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 8, lg: 8 }}
              columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={1}
              p={3}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p className="super-title">more products</p>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <h2 className="header">
                  Discover what Other products we offer
                </h2>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ height: "400px" }}>
                <Grid
                  container
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 3 }}
                  justifyContent={{ xs: "center", sm: "center", md: "center" }}
                  rowSpacing={1}
                  alignItems="center"
                  height="100%"
                >
                  {/* {product_card_details.map((val, index) => (
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="card-container">
                        <div className="content-container">
                          <div className="icon-container">
                            <img src={val.img} alt="" className="img" />
                          </div>
                          <p className="heading">{val.heading}</p>
                          <p className="text">
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Reiciendis earum voluptatibus quidem vitae et
                            perspiciatis.
                          </p>
                          <p className="route">Learn More</p>
                        </div>
                      </div>
                    </Grid>
                  ))} */}

                  <ProductsSwiperCard />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={9} lg={9} className="partners-container">
        <SwiperImages clients={clients} />
      </Grid>

      {/* <Grid item xs={12} sm={12} md={8} lg={8} className="article-section">
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          justifyContent={{ xs: "center", sm: "center", md: "center" }}
          rowSpacing={1}
          marginBottom="150px"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <p className="super-title">our work process</p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h2 className="header">Our Simplified and Effective Process</h2>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 3 }}
              justifyContent={{ xs: "center", sm: "center", md: "center" }}
              rowSpacing={1}
            >
              {product_card_details.map((val, index) => (
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className="card-container">
                    <div className="content-container">
                      <div className="icon-container">
                        <img
                          src={product_sample_image}
                          alt=""
                          className="img"
                        />
                      </div>
                      <p className="heading">Software & Hardware Product</p>
                      <p className="text">
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Reiciendis earum voluptatibus quidem vitae et
                        perspiciatis.
                      </p>
                      <p className="route">Learn More</p>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default Homepage2;
