import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Grid } from "@mui/material";
import { product_card_details } from "../navigation/utils/MainUtils";
import "./css/product_swiper.css";
// import "../../pages/homepage/css/homepage.css";

const ProductsSwiperCard = ({ image, heading }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  return (
    <Swiper
      slidesPerView={isMobile ? 1 : 3}
      spaceBetween={30}
      loop={true}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
      speed={1000}
      modules={[Pagination, Autoplay, Navigation]}
      className="mySwiper"
    >
      {product_card_details.map((val, index) => (
        <SwiperSlide key={index} id="swiper-product-container">
          <div className="card-container">
            <div className="content-container">
              <div className="icon-container">
                <img src={val.img} alt="" className="img" />
              </div>
              <p className="heading" style={{ marginBottom: 0 }}>
                {val.heading}
              </p>
              <p className="text" style={{ marginTop: 0 }}>
                {val.longtext}
              </p>
              {/* <p className="route">Learn More</p> */}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductsSwiperCard;
