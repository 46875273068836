import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// import { Autoplay, Pagination, Navigation } from "swiper/modules";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./css/swiper_styles.css";

// images from utils
// import { clients } from "../utils/ImageGalleryUtils";
const SwiperImages = ({ clients }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  return (
    <div className="swiper-main-container">
      <div className="super-text-container">
        <p>Our Clients</p>
      </div>
      <div className="swiper-container">
        <Swiper
          slidesPerView={isMobile ? 3 : 7}
          spaceBetween={30}
          loop={true}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          speed={1000}
          modules={[Pagination, Autoplay, Navigation]}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          // pagination={{ clickable: true }}

          className="mySwiper"
        >
          {clients.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="swiper-img-container">
                <img src={item.image} alt="" className="img" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SwiperImages;
