import { Grid } from "@mui/material";
import React from "react";
import "./css/stdbannercomponent.css";
import "./css/responsiveStdBannerComponent.css";

import { banner_details } from "../navigation/utils/MainUtils";
import ButtonReusable from "../buttons/ButtonReusable";
import PulseIconButton from "../buttons/PulseIconButton";
import brand from "../../assets/usi_logo2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import usi_official_logo from "../../assets/USI_official.png";
import logo_only from "../../assets/usi_logo_only.png";
const StdBannerComponent = () => {
  AOS.init();

  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
      justifyContent={{ xs: "center", sm: "center", md: "center" }}
      rowSpacing={1}
      className="banner-main-std-container"
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {banner_details.map((val, index) => (
          <div className="banner-std-container">
            <div className="banner-std-overlay-container"></div>

            <div className="banner-std-img-warpper">
              <img src={val.image} alt="" className="images" />
            </div>
            <div className="banner-std-text-container">
              <div
                className="brand-warpper"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <img src={logo_only} alt="" className="img" />
              </div>

              {/* <p
                className="sub-text"
                data-aos="fade-up"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1500"
                data-aos-delay="300"
              >
                Providing Better Solutions
              </p> */}
              <span
                data-aos="fade-up"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1300"
                data-aos-delay="400"
                style={{
                  fontSize: "32px",
                  fontWeight: 900,
                  textAlign: "center",
                }}
              >
                UTILITY SOLUTIONS INDUSTRIES, CORP.
              </span>
              <h2
                className="tagline"
                data-aos="fade-up"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1500"
                data-aos-delay="600"
              >
                {/* Perfect <span style={{ color: "#d7bc10" }}>Solutions</span> for
                Your Business */}
                Providing Better{" "}
                <span style={{ color: "#d7bc10" }}>Solutions</span>
              </h2>

              {/* <div
                className="btn-std-container"
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-delay="600"
              >
                <ButtonReusable
                  buttonName="Get Started Now"
                  backgroundColor="#436fea"
                  padding="16px 50px"
                  borderRadius="50px"
                  color="white"
                  transform="translateY(-20px)"
                  hoverBackgroundColor="white"
                  hoverColor="black"
                  fontWeight={700}
                />

                <div
                  className="pulse-container"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="800"
                >
                  <PulseIconButton />
                  <p>watch now</p>
                </div>
              </div> */}
            </div>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default StdBannerComponent;
