import React, { useEffect, useState } from "react";
import "./css/umspagestyle.css";
import image1 from "../../../assets/banners/ums_page_banner.jpg";
import { Grid } from "@mui/material";
import { BsFillPatchCheckFill } from "react-icons/bs";
import brochure from "../../../assets/brochure.png";
import dasboard1 from "../../../assets/dashboard1.png";
import dasboard2 from "../../../assets/dashboard2.png";
import pccp from "../../../assets/pccp.png";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  key_features_details,
  project_card_details,
  sys_funct_details,
} from "../../../components/navigation/utils/MainUtils";
import PulseIconButton from "../../../components/buttons/PulseIconButton";
import LightboxComponent from "../../../components/lightbox/LightBoxComponent";
const UMS = () => {
  AOS.init();

  const [openLightbox, setOpenLightbox] = useState(false);
  const [video, setVideo] = useState(null);

  const handleOpenLightbox = (video) => {
    setOpenLightbox(true);
    setVideo(video);
  };
  const handleClose = () => {
    setOpenLightbox(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div style={{ height: "" }} className="ums_page_main_container">
      {/* <div className="banner-fix-sized-overlay-container"></div>
      <div className="page_banner_container">
        <p>UMS Project Details</p>
      </div> */}

      <Grid
        container
        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
        justifyContent={{ xs: "center", sm: "center", md: "center" }}
        rowSpacing={1}
        height="100%"
        marginTop="120px"
      >
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid
            container
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            columnSpacing={{ xs: 1, sm: 1, md: 5, lg: 5 }}
            justifyContent={{ xs: "center", sm: "center", md: "center" }}
            rowSpacing={1}
          >
            {/* ums page left section */}
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <div className="left_details_container">
                <div
                  className="img-container"
                  data-aos="fade-right"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset="500"
                  data-aos-duration="1000"
                  //   data-aos-delay="600"
                >
                  <div className="cover-overlay"></div>

                  <div className="pulse-icon-container">
                    <PulseIconButton
                      onClickPulse={() =>
                        handleOpenLightbox(project_card_details[0].video)
                      }
                    />
                  </div>

                  <LightboxComponent
                    open={openLightbox}
                    close={handleClose}
                    video={video}
                  />
                  <img src={image1} alt="" className="img" />
                </div>
                <h1
                  className="heading"
                  data-aos="fade-up"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset="500"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  Utility Management System (UMS)
                </h1>
                <p
                  className="long-text"
                  data-aos="fade-up"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset="500"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  Utility Management System (UMS) Software is designed for
                  complete meter reading and monitoring that may accessed
                  locally and remotely. Our comprehensive system is designed
                  with multiple modules that cater to the diverse needs and
                  concerns of our clients, providing effective solutions in a
                  variety of ways.
                </p>

                <div className="box-wrapper">
                  <h3 className="heading">System Key Benifits</h3>
                  <p className="long-text">
                    Discover the transformative advantages embedded within our
                    system. From fortified security measures to streamlined
                    communication channels, enhanced efficiency through
                    automation, informed decision-making fueled by data
                    insights, and strategic initiatives driving revenue growth,
                    our platform empowers your business with a comprehensive
                    suite of benefits to thrive in today's dynamic landscape.
                  </p>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid
                      container
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 3 }}
                      justifyContent={{
                        xs: "center",
                        sm: "center",
                        md: "center",
                      }}
                      rowSpacing={1}
                    >
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <div className="box-img-wrapper">
                          <img src={pccp} alt="" className="img" />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={7} lg={7}>
                        <Grid
                          container
                          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                          justifyContent={{
                            xs: "center",
                            sm: "center",
                            md: "center",
                          }}
                          rowSpacing={1}
                          height="100%"
                        >
                          {key_features_details.map((item, index) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{
                                display: "flex",
                                gap: "20px",
                                alignItems: "center",
                              }}
                              key={index}
                            >
                              <BsFillPatchCheckFill
                                style={{ color: "#3567f3" }}
                              />
                              <p id="feature-text">{item.text}</p>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <div className="box-wrapper">
                  <h3 className="heading">What The System Do?</h3>
                  <p className="long-text">
                    Explore the capabilities of our system: fortified security
                    measures, streamlined communication channels, enhanced
                    efficiency through automation, data-driven decision-making
                    insights, and revenue growth strategies. Harnessing these
                    features, our system empowers your business to excel and
                    thrive in today's competitive landscape.
                  </p>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid
                      container
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 3 }}
                      justifyContent={{
                        xs: "center",
                        sm: "center",
                        md: "center",
                      }}
                      rowSpacing={1}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className="box-img-wrapper">
                          <img src={dasboard1} alt="" className="img" />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className="box-img-wrapper">
                          <img src={dasboard2} alt="" className="img" />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>

            {/* ums right section */}
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div
                className="box-wrapper-right"
                data-aos="fade-left"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1500"
                data-aos-delay="200"
              >
                <h3>System Modules</h3>
                {sys_funct_details.map((item, index) => (
                  <div className="sys-func-container" key={index}>
                    <div className="img-container">
                      <img src={item.img} alt="" className="img" />
                    </div>
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default UMS;
