import React, { useEffect } from "react";
import "yet-another-react-lightbox/styles.css";
import ReactModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import ums_video from "../../assets/vids/ums_video.mp4";

const LightboxComponent = ({ open, close, video }) => {
  useEffect(() => {
    const backdrop = document.querySelector(".modal-video");
    if (backdrop) {
      backdrop.style.backgroundColor = "rgba(0, 0, 0, 0.9)";
    }
  }, [open]);
  return (
    <ReactModalVideo
      channel="custom"
      isOpen={open}
      onClose={close}
      url={video}
    />

    // <div>
    //   <a href={video}></a>
    // </div>
  );
};

export default LightboxComponent;
