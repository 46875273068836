import React, { Suspense, lazy } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Homepage2 from "./pages/homepage/Homepage2";

import "./App.css";
import UMS from "./pages/products/ums/UMS";
import PocketHr from "./pages/products/pockethr/PocketHr";
import DefRoutePage from "./pages/products/defroutepage/DefRoutePage";

const HomePage = lazy(() => import("./pages/homepage/HomePage"));
const NavigationBar = lazy(() =>
  import("./components/navigation/NavigationBar")
);
const Services = lazy(() => import("./pages/services/Services"));

function App() {
  return (
    <HashRouter>
      <Suspense>
        <Routes>
          <Route path="/" element={<NavigationBar />}>
            <Route path="/" element={<Homepage2 />} />
            <Route path="/services" element={<Services />} />
            <Route path="/umspageroute" element={<UMS />} />
            <Route path="/pockethrroute" element={<PocketHr />} />
            <Route path="/fieldplus" element={<DefRoutePage />} />
          </Route>
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
