import React, { useEffect, useState } from "react";
import "../gencss/genstyles.css";
import primary_img from "../../../assets/banners/product.jpg";
import { Grid } from "@mui/material";
import { BsFillPatchCheckFill } from "react-icons/bs";
import {
  data,
  phr_mod_details,
  project_card_details,
  sys_funct_details,
} from "../../../components/navigation/utils/MainUtils";
import support_icon from "../../../assets/icons/support_icon.svg";
import puzzle_bulb_icon from "../../../assets/icons/puzzle_bulb_icon.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import PulseIconButton from "../../../components/buttons/PulseIconButton";
import LightboxComponent from "../../../components/lightbox/LightBoxComponent";
import ButtonReusable from "../../../components/buttons/ButtonReusable";
const PocketHr = () => {
  AOS.init();
  const [openLightbox, setOpenLightbox] = useState(false);
  const [video, setVideo] = useState(null);

  const handleOpenLightbox = (video) => {
    setOpenLightbox(true);
    setVideo(video);
  };
  const handleClose = () => {
    setOpenLightbox(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div style={{ height: "" }} className="ums_page_main_container">
      <Grid
        container
        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
        justifyContent={{ xs: "center", sm: "center", md: "center" }}
        rowSpacing={1}
        height="100%"
        marginTop="120px"
      >
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid
            container
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            columnSpacing={{ xs: 1, sm: 1, md: 5, lg: 5 }}
            justifyContent={{ xs: "center", sm: "center", md: "center" }}
            rowSpacing={1}
          >
            {/* left content */}
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <div className="left-detail-container">
                <Grid
                  container
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                  justifyContent={{ xs: "center", sm: "center", md: "center" }}
                  rowSpacing={1}
                  height="100%"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ padding: "16px" }}
                  >
                    <div
                      className="primary-image-container"
                      data-aos="fade-right"
                      data-aos-anchor="#example-anchor"
                      data-aos-offset="500"
                      data-aos-duration="1000"
                    >
                      <div className="cover-overlay"></div>

                      <div className="pulse-icon-container">
                        <PulseIconButton
                          onClickPulse={() =>
                            handleOpenLightbox(project_card_details[1].video)
                          }
                        />
                      </div>

                      <img src={primary_img} alt="" className="img" />

                      <LightboxComponent
                        open={openLightbox}
                        close={handleClose}
                        video={video}
                      />
                    </div>
                    <h1
                      data-aos="fade-up"
                      data-aos-anchor="#example-anchor"
                      data-aos-offset="500"
                      data-aos-duration="1000"
                      data-aos-delay="200"
                    >
                      HR Management System (Pocket HR)
                    </h1>
                    <p
                      style={{ textAlign: "justify" }}
                      data-aos="fade-up"
                      data-aos-anchor="#example-anchor"
                      data-aos-offset="500"
                      data-aos-duration="1000"
                      data-aos-delay="300"
                    >
                      A modern HR management system serves as the cornerstone of
                      organizational efficiency and employee satisfaction. By
                      seamlessly integrating various HR functions such as
                      recruitment, onboarding, performance evaluation, and
                      payroll processing, it streamlines operations and fosters
                      a cohesive work environment
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid
                      container
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      columnSpacing={{ xs: 1, sm: 1, md: 5, lg: 5 }}
                      justifyContent={{
                        xs: "center",
                        sm: "center",
                        md: "center",
                      }}
                      rowSpacing={1}
                      height="100%"
                      p={2}
                    >
                      {/* left section of section2 */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className="secondary-img-container">
                          <img src={primary_img} alt="" className="img" />
                        </div>
                      </Grid>
                      {/* right section of section 2 */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid
                          container
                          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                          justifyContent={{
                            xs: "center",
                            sm: "center",
                            md: "center",
                          }}
                          rowSpacing={1}
                          height="100%"
                        >
                          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                          <h1>HR Management System (Pocket HR)</h1>
                        </Grid> */}

                          {data.map((item, index) => (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "16px",
                              }}
                            >
                              <BsFillPatchCheckFill
                                style={{ color: "green" }}
                              />
                              <p style={{ margin: 0 }}>{item.text}</p>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p style={{ textAlign: "justify" }}>
                          Leveraging advanced technologies like cloud computing
                          and data analytics, these systems provide invaluable
                          insights into workforce trends, enabling informed
                          decision-making. Furthermore, with features such as
                          self-service portals and mobile applications, HR
                          management systems empower employees to take control
                          of their own HR-related tasks, ultimately enhancing
                          engagement and productivity across the organization.
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid
                          container
                          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                          justifyContent={{
                            xs: "center",
                            sm: "center",
                            md: "center",
                          }}
                          rowSpacing={1}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <h1 style={{ width: "100%" }}>
                                Benifits With Our Service
                              </h1>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Grid
                                container
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                                columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                                justifyContent={{
                                  xs: "center",
                                  sm: "center",
                                  md: "center",
                                }}
                                rowSpacing={1}
                              >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <div className="benifit-card-container">
                                    <div className="icon-container">
                                      <img src={puzzle_bulb_icon} alt="" />
                                    </div>
                                    <div className="text-container">
                                      <h3>Flexible Solutions</h3>
                                      <p>
                                        Adapt swiftly to changing market demands
                                      </p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <div className="benifit-card-container">
                                    <div className="icon-container">
                                      <img src={support_icon} alt="" />
                                    </div>
                                    <div className="text-container">
                                      <h3>24/7 Support</h3>
                                      <p>
                                        Enhancing satisfaction and fostering
                                        long-term loyalty
                                      </p>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {/* right details container */}
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div
                className="box-wrapper-right"
                data-aos="fade-left"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="1500"
                data-aos-delay="200"
              >
                <h3>What Pocket HR can Do?</h3>
                {phr_mod_details.map((item, index) => (
                  <div className="sys-func-container" key={index}>
                    <div className="img-container">
                      <img src={item.img} alt="" className="img" />
                    </div>

                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PocketHr;
