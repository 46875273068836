import React from "react";
import Button from "@mui/material/Button";
const ButtonReusable = (props) => {
  return (
    <div>
      <Button
        disabled={props.disabled}
        variant={props.variant}
        size={props.size}
        onClick={props.onClick}
        type={props.type}
        fullWidth={props.fullWidth}
        sx={{
          color: props.color,
          padding: props.padding,
          backgroundColor: props.backgroundColor,
          height: props.height,
          width: props.width,
          fontSize: props.fontSize,
          fontWeight: props.fontWeight,
          marginRight: props.marginRight,
          marginTop: props.marginTop,
          border: props.border,
          borderRadius: props.borderRadius,
          transform: `translateY(${props.transform || "0px"})`,
          transition: "transform 0.3s ease-out,background-color 0.3s ease-out",

          "&:hover": {
            backgroundColor: props.backgroundColor,
            backgroundColor:
              props.hoverBackgroundColor || props.backgroundColor,
            color: props.hoverColor || props.color,
            transform: `translateY(${props.transformOnHover || "-5px"})`,
          },
          textTransform: "capitalize",
          // -webkit-box-shadow: 8px 7px 66px -2px rgba(204,210,230,0.75);
          // -moz-box-shadow: 8px 7px 66px -2px rgba(204,210,230,0.75);
        }}
      >
        {props.buttonName}
      </Button>
    </div>
  );
};

export default ButtonReusable;
