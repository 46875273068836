import image_banner1 from "../../../assets/banners/image_banner1.jpg";
import { MdComputer } from "react-icons/md";
import service_grid_1 from "../../../assets/icons/service_grid_1.svg";
import service_grid_2 from "../../../assets/icons/service_grid_2.svg";
import service_grid_3 from "../../../assets/icons/service_grid_3.svg";
import service_grid_4 from "../../../assets/icons/service_grid_4.svg";
// pocket hr details images
import web_security from "../../../assets/icons/web_security.png";
import payroll from "../../../assets/icons/PAYROLL.png";
import scan from "../../../assets/icons/scan.png";
import paperless from "../../../assets/icons/Paperless.png";
import accuracy from "../../../assets/icons/accuracy.png";
import qr_code from "../../../assets/icons/qr_code.png";
import adapt from "../../../assets/icons/adapt.png";

// field modules images
import accomplishment_report from "../../../assets/icons/accomplishment_report.png";
import account_monitoring from "../../../assets/icons/account_monitoring.png";
import realtime_data from "../../../assets/icons/realtime_data.png";
import data_export from "../../../assets/icons/data_export.png";
import field_tracking from "../../../assets/icons/field_tracking.png";

// image import for work process section
import process_box_1 from "../../../assets/icons/process_box_1.png";
import process_box_2 from "../../../assets/icons/process_box_2.png";
import process_box_3 from "../../../assets/icons/process_box_3.png";
import process_box_4 from "../../../assets/icons/process_box_4.png";

// system funtionalities images imports
import crm from "../../../assets/icons/CRM.png";
import billing from "../../../assets/icons/BILLING.png";
import work_order from "../../../assets/icons/WORK_ORDER.png";
import field_and_tracking from "../../../assets/icons/FIELD_AND_TRACKING.png";
import collection from "../../../assets/icons/COLLECTION.png";

// images for clients
import alway from "../../../assets/clients/always.png";
import batelec from "../../../assets/clients/baletec.png";
import clarkl_electric from "../../../assets/clients/clark_electric.png";
import cozy from "../../../assets/clients/cozy.png";
import mmsc from "../../../assets/clients/mmsc.png";
import greenzone from "../../../assets/clients/greenzone.png";
import ngc_enterprise from "../../../assets/clients/ngc_enterprice.png";
import ngc_express from "../../../assets/clients/ngc_expres.png";
import usi from "../../../assets/clients/usi.png";
import mnwd from "../../../assets/clients/manila_water.png";
import ileco2 from "../../../assets/clients/ileco2.jpg";
import baliwag from "../../../assets/clients/baliwag.jpg";
import morepower from "../../../assets/clients/morepower.jpg";

// footer details
import { FaSquarePhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";

// projects

import ums from "../../../assets/icons/ums .png";
import pockethr from "../../../assets/icons/pockethr.png";
import fieldplus from "../../../assets/icons/FIELD PLUS.png";

// what we do images
import devops from "../../../assets/icons/devops.png";
import chat from "../../../assets/icons/chat.png";
import smartphone from "../../../assets/icons/smartphone.png";
import logistics from "../../../assets/icons/logistics.png";

// product list images
import thermal_paper_printer from "../../../assets/products/thermal_paper_printer.jpg";
import cctv_uniview2 from "../../../assets/products/cctv_uniview2.jpg";
import cctv_uniview3 from "../../../assets/products/cctv_uniview3.jpg";
import cloud from "../../../assets/products/0CLOUD.png";
import dev from "../../../assets/products/0DEV.png";
import printer from "../../../assets/products/01PRINTER.png";
import rover from "../../../assets/products/01ROVER.png";
import electric_meter from "../../../assets/products/electric_meter.jpg";
import smart_water_meter from "../../../assets/products/smart_water_meter.png";
import cloud_services from "../../../assets/icons/cloud_services.png";
import software from "../../../assets/icons/software.png";
import rover1 from "../../../assets/products/rover1.jpg";
import mz320printer from "../../../assets/products/mz320printer.jpg";
import zebraP4T from "../../../assets/products/zebraP4T.jpg";
import Zebra_ZQ220 from "../../../assets/products/ZebraZQ220.jpg";
import Zebra_MC2700_rover from "../../../assets/products/Zebra_MC2700_rover.jpg";

import ums_video from "../../../assets/vids/ums_video.mp4";
import pockethr_video from "../../../assets/vids/pockethr_video.mp4";
import fieldplus_video from "../../../assets/vids/fieldplus.mp4";

export const banner_details = [
  { image: image_banner1, tagline: "", subtext: "" },
  // { image: image_banner1, tagline: "", subtext: "" },
  // { image: image_banner1, tagline: "", subtext: "" },
];

// what we do section details for the card

export const what_we_do_card_details = [
  {
    icon: devops,
    heading: "Software Development",
    text: "We specialize in developing custom software tailored to your unique business needs. Whether you require a robust web application, a mobile app, or a comprehensive enterprise solution.",
  },
  {
    icon: chat,
    heading: "IT Consultation",
    text: "Our IT consultants conduct comprehensive assessments of your current technology infrastructure. From hardware and software , we identify opportunities for cost reduction, and enhanced performance.",
  },
  // {
  //   icon: smartphone,
  //   heading: "Mobile Application Development",
  //   text: "Reach a wider audience with our proficiency in both iOS and Android app development. Our experienced team leverages the latest technologies to create seamless, user-friendly experiences on every mobile platform.",
  // },
  {
    icon: logistics,
    heading: "Hardware Supply",
    text: "Tailor your hardware procurement to match your specific requirements. Our team works closely with you to understand your technology needs,  crafting customized procurement strategies that align with your business objectives.",
  },
];

export const faqs_accordion_details = [
  { header: "How can we help your business?", longtext: "" },
  { header: "How can we help your business?", longtext: "" },
  { header: "How can we help your business?", longtext: "" },
  { header: "How can we help your business?", longtext: "" },
];

export const project_card_details = [
  {
    img: ums,
    heading: "Utility Mangement System",
    longtext:
      "Utility Management System (UMS) Software is designed for complete meter reading and monitoring that may accessed locally and remotely.",
    // video: "https://www.youtube.com/watch?v=oMpSNgxfZBk",
    video: ums_video,

    route: "/umspageroute",
  },
  {
    img: pockethr,
    heading: "HR Management System",
    longtext:
      "Employee Self-Service empowers individuals to access and update their information. The system provides analytics for strategic decision-making.",
    // video: "https://www.youtube.com/watch?v=oMpSNgxfZBk",
    video: pockethr_video,

    route: "/pockethrroute",
  },
  {
    img: fieldplus,
    heading: "Field Management System",
    longtext:
      "It facilitates the management of remote or mobile workforce, improving coordination, efficiency, and communication. ",
    // video: "https://www.youtube.com/watch?v=ZuPOPKLW_co",
    video: fieldplus_video,

    route: "/fieldplus",
  },
];

// work progress section

export const work_progress_card_details = [
  {
    img: process_box_1,
    heading: "Select a Project",
    longtext:
      "Dive into our intuitive work process that seamlessly combines innovation and expertise, ensuring your vision transforms into reality with precision and flair.",
  },
  {
    img: process_box_2,
    heading: "Project Analysis",
    longtext:
      "Delve into Project Analysis, where we meticulously dissect your goals and challenges. Our keen examination paves the way for a tailored strategy, ensuring each project is met with a roadmap that aligns perfectly with your aspirations.",
  },
  {
    img: process_box_3,
    heading: "Plan & Execute",
    longtext:
      "We begin by listening closely to your vision, objectives, and challenges. Through in-depth discussions, we gain valuable insights into your business needs and aspirations.",
  },
  {
    img: process_box_4,
    heading: "Deliver Results",
    longtext:
      "We understand the importance of deadlines. Our streamlined processes and efficient workflows ensure that projects are delivered on time, every time. You can trust us to meet your deadlines without compromising on quality.",
  },
];

// product section details
export const product_card_details = [
  {
    img: zebraP4T,
    heading: "Zebra P4T - Printer / 4inch thermal",
    longtext:
      "The Zebra P4T combines a powerful set of features that will work in sync with all your mobile printing business applications.",
  },
  {
    img: Zebra_ZQ220,
    heading: "Zebra ZQ220 - printer / 3inch thermal",
    longtext:
      "Offering the perfect balance of cost, quality, versatility, durability, 'wearability' and ease-of-use. ",
  },
  {
    img: mz320printer,
    heading: "IMZ320 MOBILE PRINTER ",
    longtext:
      "Receipt printers support Bluetooth connectivity to Apple iOS, as well as Android, Windows Mobile and BlackBerry platforms",
  },
  {
    img: cctv_uniview2,
    heading: "IPC3532LB-ADZK-H",
    longtext:
      "2MP HD IR VF Dome Network Camera, High quality image with 2MP, 1/2.7 CMOS sensor, 2MP (1920*1080)@30/25fps; 720P (1280*720)@30/25fps",
  },
  {
    img: cctv_uniview3,
    heading: "IPC2122LB-ADF28(40)KM-H",
    longtext:
      "2MP HD WDR Fixed IR Bullet Network Camera, High quality image with 2MP, 1/2.7 CMOS sensor,120dB true WDR technology enables clear image in strong light scene ",
  },

  // {
  //   img: cloud_services,
  //   heading: "Cloud Services",
  //   longtext: "",
  // },
  // {
  //   img: software,
  //   heading: "Systems",
  //   longtext: "",
  // },

  {
    img: rover1,
    heading: "Honeywell ScanPal EDA51",
    longtext:
      "Built for all-day use, with a removable 4,000 mAh lithium ion battery pack that lasts 12 hours without adding weight or bulk.",
  },
  {
    img: Zebra_MC2700_rover,
    heading: "Zebra MC2700",
    longtext:
      "When it comes to empowering workers in small and medium-sized businesses, the MC2200 and MC2700 deliver ‘no compromise’ affordable mobility",
  },
  {
    img: electric_meter,
    heading: "Electric Meter: PACIFIC METER (LIBRA3)",
    longtext:
      "Multi-function Energy Meter, Comply with ANSI C12.20 and GWH0034, GWH0009, G0005, G0048, Voltage/Current: 120V-480V (±30%) / CT:CL20A, SC:CL100/200A",
  },
  {
    img: smart_water_meter,
    heading: "Smart Water Meter I-110 Series",
    longtext:
      "DN15~DN32, Multi-jet, Super dry, ISO 4064/EN14154/OIML R49 Standard Class B/Class C or R80/R100/R160, MAP16, ΔP63, T50",
  },
];

// clients gallery section

export const clients = [
  { image: alway },
  { image: mnwd },
  { image: cozy },
  { image: mmsc },
  { image: greenzone },
  { image: ngc_enterprise },
  { image: ngc_express },
  { image: usi },
  { image: ileco2 },
  { image: baliwag },
  { image: morepower },

  { image: alway },
  { image: mnwd },
  { image: cozy },
  { image: mmsc },
  { image: greenzone },
  { image: ngc_enterprise },
  { image: ngc_express },
  { image: usi },
  { image: ileco2 },
  { image: baliwag },
  { image: morepower },
];

export const footer_contactlink_details = [
  {
    title: "About Us",
    route: "",
    sectionId: "about-section",
  },
  {
    title: "Products",
    route: "",
    sectionId: "products-section",
  },
  {
    title: "Services",
    route: "",
    sectionId: "services-section",
  },
];

export const footer_quicklink_details = [
  {
    title: "Phone Number",
    route: "",
    icon: <FaSquarePhone />,
    data: "(+63) 919-079-2240",
  },
  {
    title: "Email Address",
    route: "",
    icon: <MdEmail />,
    data: "busdev@usiphil.com",
  },
  {
    title: "Location",
    route: "",
    icon: <IoLocationSharp />,
    data: "1201 Spectrum Midway Ext. Alabang, Ph.",
  },
];

export const key_features_details = [
  { text: "Enhanced website security measures." },
  { text: "Communication efficiency significantly boosted" },
  { text: "Optimal efficiency via automated processes." },
  { text: "Data-powered strategies for smart decisions" },
  { text: "Revenue maximization through targeted efforts" },
  { text: "Increased efficiency via automated tasks" },
  { text: "Increased profits through revenue optimization." },
];

export const sys_funct_details = [
  { img: crm, text: "Customer Management System" },
  { img: billing, text: "Billing Management System" },
  { img: work_order, text: "Work Order Management System" },
  { img: field_and_tracking, text: "Fiend And Tracking Management" },
  { img: collection, text: "Collection Management System" },
];

export const phr_mod_details = [
  { img: scan, text: "Geoforced Mobile Attendance" },
  { img: payroll, text: "Payroll Management" },
  { img: paperless, text: "Onnline Request & Approval" },
  { img: qr_code, text: "Empoyee Management" },
  { img: web_security, text: "Ensure Web Security" },
];
export const field_mod_details = [
  { img: field_tracking, text: "Fieldman monitoring" },
  { img: accomplishment_report, text: "Daily Accomplishment Report" },
  { img: account_monitoring, text: "Locate Account Location" },
  { img: data_export, text: "Customizable Data Export" },
  { img: realtime_data, text: "Realtime" },
];

export const data = [
  { img: crm, text: "Stay Ahead with Fieldman Monitoring" },
  { img: crm, text: "Daily Accomplishment Report" },
  { img: crm, text: "Find. Track. Conquer. Locate Account" },
  { img: crm, text: "Data Freedom Unleashed" },
  { img: crm, text: "Realtime Insight, Realtime Impact" },
];

// export const fieldplus_features_details = [
//   { text: "Enhanced website security measures." },
//   { text: "Communication efficiency significantly boosted" },
//   { text: "Optimal efficiency via automated processes." },
//   { text: "Data-powered strategies for smart decisions" },
//   { text: "Revenue maximization through targeted efforts" },
//   { text: "Increased efficiency via automated tasks" },
//   { text: "Increased profits through revenue optimization." },
// ];
