import React, { useEffect, useState } from "react";
import "../gencss/genstyles.css";
import primary_img from "../../../assets/banners/product.jpg";
import default_img from "../../../assets/banners/default_img.png";

import discon from "../../../assets/banners/discon.jpg";
import utilities from "../../../assets/banners/utilities.jpg";
import field_management_system from "../../../assets/banners/field_management_system.jpg";
import { Grid } from "@mui/material";
import { BsFillPatchCheckFill } from "react-icons/bs";
import {
  data,
  field_mod_details,
  phr_mod_details,
  project_card_details,
  sys_funct_details,
} from "../../../components/navigation/utils/MainUtils";
import support_icon from "../../../assets/icons/support_icon.svg";
import puzzle_bulb_icon from "../../../assets/icons/puzzle_bulb_icon.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import PulseIconButton from "../../../components/buttons/PulseIconButton";
import LightboxComponent from "../../../components/lightbox/LightBoxComponent";

const DefRoutePage = () => {
  AOS.init();
  const [openLightbox, setOpenLightbox] = useState(false);
  const [video, setVideo] = useState(null);

  const handleOpenLightbox = (video) => {
    setOpenLightbox(true);
    setVideo(video);
  };
  const handleClose = () => {
    setOpenLightbox(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
      justifyContent={{ xs: "center", sm: "center", md: "center" }}
      rowSpacing={1}
      marginTop="120px"
    >
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          columnSpacing={{ xs: 1, sm: 1, md: 5, lg: 5 }}
          justifyContent={{ xs: "center", sm: "center", md: "center" }}
          rowSpacing={1}
          p={2}
        >
          {/* left content */}
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div className="left-detail-container">
              <Grid
                container
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                justifyContent={{ xs: "center", sm: "center", md: "center" }}
                rowSpacing={1}
                height="100%"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ padding: "16px" }}
                >
                  <div
                    className="primary-image-container"
                    data-aos="fade-right"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="1000"
                  >
                    <div className="cover-overlay"></div>

                    <div className="pulse-icon-container">
                      <PulseIconButton
                        onClickPulse={() =>
                          handleOpenLightbox(project_card_details[2].video)
                        }
                      />
                    </div>
                    <img src={utilities} alt="" className="img" />

                    <LightboxComponent
                      open={openLightbox}
                      close={handleClose}
                      video={video}
                    />
                  </div>
                  <h1
                    data-aos="fade-up"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    Field Management System (Field++)
                  </h1>
                  <p
                    style={{ textAlign: "justify" }}
                    data-aos="fade-up"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    Field management system revolutionizes the way organizations
                    oversee and optimize their field operations. By leveraging
                    cutting-edge technology, such as GPS tracking and mobile
                    applications, it provides real-time visibility into field
                    activities, enabling businesses to monitor their field
                    workforce with unprecedented accuracy and efficiency. From
                    scheduling appointments and dispatching tasks to tracking
                    work progress and managing inventory, every aspect of field
                    operations becomes streamlined and synchronized.
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 5, lg: 5 }}
                    justifyContent={{
                      xs: "center",
                      sm: "center",
                      md: "center",
                    }}
                    rowSpacing={1}
                    height="100%"
                    p={2}
                  >
                    {/* left section of section2 */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className="secondary-img-container">
                        <img src={discon} alt="" className="img" />
                      </div>
                    </Grid>
                    {/* right section of section 2 */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Grid
                        container
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                        justifyContent={{
                          xs: "center",
                          sm: "center",
                          md: "center",
                        }}
                        rowSpacing={1}
                        height="100%"
                      >
                        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                          <h1>HR Management System (Pocket HR)</h1>
                        </Grid> */}

                        {data.map((item, index) => (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                            }}
                          >
                            <BsFillPatchCheckFill
                              style={{ color: "#014065" }}
                            />
                            <p style={{ margin: 0, fontWeight: 500 }}>
                              {item.text}
                            </p>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <p style={{ textAlign: "justify" }}>
                        This not only enhances productivity and reduces
                        operational costs but also ensures better customer
                        service through timely responses and optimized resource
                        allocation. With customizable features tailored to
                        specific industry needs, a field management system
                        empowers organizations to stay agile, competitive, and
                        ahead of the curve in today's dynamic business
                        landscape.
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid
                        container
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                        justifyContent={{
                          xs: "center",
                          sm: "center",
                          md: "center",
                        }}
                        rowSpacing={1}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <h1 style={{ width: "100%" }}>
                              Benifits With Our Service
                            </h1>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid
                              container
                              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                              columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                              justifyContent={{
                                xs: "center",
                                sm: "center",
                                md: "center",
                              }}
                              rowSpacing={1}
                            >
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="benifit-card-container">
                                  <div
                                    className="icon-container"
                                    style={{ backgroundColor: "#014065" }}
                                  >
                                    <img src={puzzle_bulb_icon} alt="" />
                                  </div>
                                  <div className="text-container">
                                    <h3>Flexible Solutions</h3>
                                    <p>
                                      Adapt swiftly to changing market demands
                                    </p>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="benifit-card-container">
                                  <div
                                    className="icon-container"
                                    style={{ backgroundColor: "#014065" }}
                                  >
                                    <img src={support_icon} alt="" />
                                  </div>
                                  <div className="text-container">
                                    <h3>24/7 Support</h3>
                                    <p>
                                      Enhancing satisfaction and fostering
                                      long-term loyalty
                                    </p>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          {/* right details container */}
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div
              className="box-wrapper-right"
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <h3>What Field++ can Do?</h3>
              {field_mod_details.map((item, index) => (
                <div className="sys-func-container" key={index}>
                  <div className="img-container">
                    <img
                      src={item.img === "" ? default_img : item.img}
                      alt=""
                      className="img"
                    />
                  </div>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DefRoutePage;
